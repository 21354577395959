import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Hizmetler | FEEL Tennis Academy
			</title>
			<meta name={"description"} content={"Birinci Sınıf Tenis Deneyiminiz"} />
			<meta property={"og:title"} content={"Hizmetler | FEEL Tennis Academy"} />
			<meta property={"og:description"} content={"Birinci Sınıf Tenis Deneyiminiz"} />
			<meta property={"og:image"} content={"https://amberglowsanctum.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://amberglowsanctum.com/img/45386854.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<meta name={"msapplication-TileImage"} content={"https://amberglowsanctum.com/img/45386854.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				FEEL Kortları ile En İyisini Deneyimleyin
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 180px 0px 180px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				FEEL Kortlarında bütünsel bir tenis deneyimi sunmaya inanıyoruz. Hizmetlerimiz her seviyeden oyuncuya hitap edecek şekilde tasarlanmıştır ve herkesin oyununu geliştirecek ve kortta geçirdiği zamandan keyif almasını sağlayacak bir şeyler bulmasını sağlar. Profesyonel koçluktan birinci sınıf olanaklara kadar, oyununuzu bir üst seviyeye taşımak için ihtiyacınız olan her şeyi sağlıyoruz.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://amberglowsanctum.com/img/3.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Profesyonel Koçluk
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Profesyonel koçluk hizmetlerimiz, becerilerinizi geliştirmenize ve oyununuzu yükseltmenize yardımcı olmak için özel olarak tasarlanmıştır. Deneyimli koçlarımız tekniğinizi, stratejinizi ve genel performansınızı geliştirmeye odaklanan kişiselleştirilmiş eğitim seansları sunar.
							<br />
							Özel Dersler: Özel ihtiyaçlarınızı ve hedeflerinizi karşılamak için tasarlanmış bire bir koçluk seansları.
							<br />
							Grup Klinikleri: Odaklanmış eğitim ve beceri gelişimi için küçük grup oturumlarına katılın.
							<br />
							Genç Programları: Genç oyuncuların erken yaşlardan itibaren becerilerini geliştirmeleri için özel eğitim programları.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://amberglowsanctum.com/img/4.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Premium Kort Bakımı
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Kortlarımızın bakımını en yüksek standartlarda yapmaktan büyük gurur duyuyoruz. Toprak kortlarımız, en uygun oyun koşullarını sağlamak için düzenli olarak denetlenmekte ve bakımdan geçirilmektedir.
							<br />
							Günlük Bakım: Ekibimiz kortları mükemmel durumda tutmak için günlük bakım yapmaktadır.
							<br />
							Mevsimsel Bakım: Her türlü aşınma ve yıpranmayı gidermek ve uzun vadeli kaliteyi sağlamak için kapsamlı mevsimsel bakım.
							<br />
							Kort Rezervasyonları: Programınıza uygun esnek rezervasyon seçenekleri, istediğiniz zaman oynamanıza olanak tanır.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://amberglowsanctum.com/img/5.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Kapsamlı Olanaklar
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						FEEL Tennis Academy, ziyaretinizi konforlu ve keyifli hale getirmek için bir dizi olanak sunmaktadır. Dinlenme alanlarından temel olanaklara kadar ihtiyacınız olan her şeye sahibiz.
							<br />
							Dinlenme Alanları: Oyundan önce ve sonra dinlenebileceğiniz konforlu alanlar.
							<br />
							Soyunma Odaları: Rahatınız için güvenli ve temiz soyunma odaları.
							<br />
							Pro Shop: Yüksek kaliteli tenis ekipmanlarına ve kıyafetlerine erişim.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://amberglowsanctum.com/img/2.jpg) 50% 50% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					FEEL Tennis Academy
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white" width="50%">
				FEEL Kortlarında Bize Katılın!
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base" width="50%">
				FEEL Tennis Academy'u tenis tutkunları için önde gelen bir destinasyon haline getiren kapsamlı hizmetleri deneyimleyin. Oyununuzu geliştirin, en kaliteli tesislerin keyfini çıkarın ve canlı tenis topluluğumuzun bir parçası olun. Mükemmel oyun deneyiminiz sizi bekliyor!
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					background="--color-dark"
					padding="12px 28px 12px 28px"
				>
					Bize Ulaşın
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});